import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withTranslation, Trans } from 'react-i18next'

import { TEXT_STYLES } from 'TEXT_STYLES'

import { Button, ButtonType } from '@junglescout/edna'
import PpcSyncLwa from 'ui_elements/PpcSyncLwa/PpcSyncLwa'

import { AMAZON_MWS_PPC_SYNC_STEPS, PPC_SYNC_STATUS } from 'constants/account'
import { MWS_SYNC_DATA_ATTRIBUTE_IDS } from 'constants/data_attributes/account/mwsSync'

import { AbstractModal, Footer } from './Abstract'

const P = styled.p`
  ${TEXT_STYLES.H4Black}
  margin: 0;
  margin-bottom: 20px;
`
const InitialWrapper = styled.div`
  margin-top: 20px;
  height: 340px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  padding: 15px;
`

const RetryWrapper = styled.div`
  margin-top: 30px;
`
const RetryMessageWrapper = styled.div`
  ${TEXT_STYLES.BodyBlack}
  margin-top: 20px;
  b {
    font-weight: 500;
  }
`

class PpcSyncStep extends AbstractModal {
  renderContent() {
    const {
      ppcSyncStatus,
      setPpcSyncStatus,
      t,
      fromSetupPage,
      region
    } = this.props
    const header = t('mwsSync:PpcSyncStep.header', 'Sync PPC data')
    const ppcSync = (
      <PpcSyncLwa
        btnType={ButtonType.SECONDARY}
        ppcSyncStatus={ppcSyncStatus}
        setPpcSyncStatus={setPpcSyncStatus}
        sellerAccountId={this.props.sellerAccountId}
        fromSetupPage={fromSetupPage}
        withMwsSync
        region={region}
      />
    )
    switch (ppcSyncStatus) {
      case PPC_SYNC_STATUS.initial:
        return (
          <InitialWrapper>
            <P>{header}</P>
            {ppcSync}
          </InitialWrapper>
        )
      case PPC_SYNC_STATUS.failure:
        return (
          <RetryWrapper>
            {ppcSync}
            <RetryMessageWrapper data-testid="ppc-sync-failure-status">
              <Trans i18nKey="mwsSync:PpcSyncStep.header">
                Click <b>“Skip &amp; Next”</b> to skip PPC data sync. You can go
                to Settings when ready to enable your PPC data.
              </Trans>
            </RetryMessageWrapper>
          </RetryWrapper>
        )
      default:
        return null
    }
  }

  renderFooter() {
    const { t, fromSetupPage } = this.props
    const nextButtonLabel = t('generic:SkipAndNext', 'Skip & Next')
    const dataId = fromSetupPage
      ? MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.SetupWizardPpcSkipAndNext
      : MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.PpcSkipAndNext
    return (
      <Footer>
        <Button
          btnType={ButtonType.PRIMARY}
          onClick={() => this.onNextClick()}
          isLoading={this.nextButtonLoading}
          disabled={this.nextButtonDisabled}
          dataId={dataId}>
          {nextButtonLabel}
        </Button>
      </Footer>
    )
  }

  onNextClick() {
    this.props.setPpcSyncStepSkipped(true)
    this.props.showMwsSyncModal(this.nextModal, this.props.isUpgrade)
  }

  get nextModal() {
    return AMAZON_MWS_PPC_SYNC_STEPS.accountSetup
  }

  get nextButtonDisabled() {
    return false
  }

  get backButtonDisabled() {
    return true
  }

  get activeStepIndex() {
    return 2
  }
}

PpcSyncStep.propTypes = {
  setPpcSyncStepSkipped: PropTypes.func,
  ppcSyncStatus: PropTypes.string,
  setPpcSyncStatus: PropTypes.func,
  region: PropTypes.string
}

export default withTranslation(['mwsSync'])(PpcSyncStep)
